html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

body {
  font-family: Helvetica, sans-serif;
  height: 100vh;
  width: 100%;
}

body header {
  position: relative;
}

body header span {
  bottom: 0;
  font-size: 3em;
  font-weight: 700;
  left: 0;
  line-height: 1;
  margin: 0.67em;
  position: fixed;
  text-transform: uppercase;
}
